import { WFComponent } from "@xatom/core";
import { logout } from "./index";

export const initNavbar = () => {
  const navbar = new WFComponent("[ss-component='dashboard-navbar']");
  console.log("Navbar", navbar);


  if (!navbar) {
    return; // Exit if navbar doesn't exist
  }

  const logoutBtn = navbar.getChildAsComponent("[ss-func-btn='logout']");

  if (!logoutBtn) {
    return; // Exit if logout button doesn't exist
  }

  // Add click handler for logout
  logoutBtn.on("click", (e) => {
    e.preventDefault();
    const originalText = logoutBtn.getText() || "Logout";

    logoutBtn.setText("Logging out...");
    logoutBtn.setAttribute("disabled", "true");


    logout().finally(() => {
      logoutBtn.setText(originalText);
      logoutBtn.removeAttribute("disabled");
    });
  });
};