export const websiteRoutes = {
  home: "/",
  login: "/app/login",
  signup: "/app/signup",
  verify: "/app/verify",
  dashboard: "/components/",
  inspiration: "/inspiration/",
  inspirationsHome: "/inspirations",
  componentPageUrl: "/components/",
  preview: "/preview",
};

export const SUPABASE_REDIRECT_URL = `${location.protocol}//${location.host}${websiteRoutes.verify}`;
