import { WFRoute, navigate } from "@xatom/core";
import { userMiddleware } from "../modules/auth";
import posthog from 'posthog-js';
import { websiteRoutes } from "../config";

export const app = () => {
  // Track page views
  const trackPageView = (path: string) => {
    posthog.capture("$pageview", { path });
  };

  new WFRoute("/(.*)").execute(() => {
    console.log("Load posthog scripts");
    const urlPath = window.location.pathname;
    console.log(urlPath);

    trackPageView(urlPath);
  });

  new WFRoute("/").execute(() => {
    console.log("Load dashboard scripts saif");

    console.warn("Load inspirations scripts saif dashboard");
    import("../modules/inspirationsListing")
      .then(({ initInspirationHandlerPage }) => initInspirationHandlerPage())
      .catch(console.error);

    console.warn("Load components scripts saif dashboard");
    import("../modules/component")
      .then(({ initComponentsPage }) => initComponentsPage())
      .catch(console.error);
  });

  //will trigger route matches /auth/*
  new WFRoute("/app/(.*)")
    .withMiddleware(userMiddleware, "GUEST", "allow", {
      onError() {
        navigate("/components/");
      },
    })
    .execute(() => {
      //will trigger route matches /auth/sign-up
      new WFRoute("/app/login").execute(() => {
        //lazy loading route
        console.log("Load login scripts");

        import("../modules/auth/login")
          .then(({ login }) => login())
          .catch(console.error);
      });
      //will trigger route matches /auth/sign-in
      new WFRoute("/app/signup").execute(() => {
        //lazy loading route
        console.log("Load signup scripts");

        import("../modules/auth/signUp")
          .then(({ signUp }) => signUp())
          .catch(console.error);
      });
      //will trigger route matches /auth/verify
      new WFRoute("/app/verify").execute(() => {
        //lazy loading route
        import("../modules/auth/verify")
          .then(({ verify }) => verify())
          .catch(console.error);
      });
    });

  new WFRoute("/inspirations").execute(() => {
    console.log("Load inspirations scripts saif");
    import("../modules/inspirationsListing")
      .then(({ initInspirationHandlerPage }) => initInspirationHandlerPage())
      .catch(console.error);
  });

  new WFRoute("/inspiration/(.*)").execute(() => {
    //will trigger route matches /app/task-list
    console.log("Load inspiration scripts in detail");
    import("../modules/inspiration")
      .then(({ initInspirationPage }) => initInspirationPage())
      .catch(console.error);
  });

  new WFRoute("/preview").execute((params: { comp_id: string }) => {
    console.log(params.comp_id); // Access query parameters (e.g., 123)

    import("../modules/preview")
      .then(({ Preview }) => {
        new Preview(params.comp_id).init();
      })
      .catch(console.error);
  });

   new WFRoute("/components").execute(() => {
     import("../modules/component")
       .then(({ initComponentsPage }) => initComponentsPage())
       .catch(console.error);
   });

   new WFRoute("/components/(.*)").execute(() => {
     import("../modules/component")
       .then(({ initComponentsPage }) => initComponentsPage())
       .catch(console.error);
   });

   new WFRoute("/component/(.*)").execute(() => {
     import("../modules/component")
       .then(({ initComponentsPage }) => initComponentsPage())
       .catch(console.error);
   });

  new WFRoute("/favorites")
    .withMiddleware(userMiddleware, "USER", "allow", {
      onError() {
        navigate(websiteRoutes.login);
      },
    })
    .execute(() => {
      //lazy loading route
      console.log("Load favorites scripts");

      import("../modules/favorites")
        .then(({ Favorites }) => new Favorites())
        .catch(console.error);
    });
};
