import { onReady } from "@xatom/core";
import { app } from "./routes";
import "./modules/supbase";
import { initSupabase } from "./modules/supbase";
import { initNavbar } from "./modules/auth/navbar";
import { initPostHog } from "./utils/posthog";

(window as any).WFDebug = true;

console.log("Loading ShaikSaif Powers");

onReady(() => {
  initPostHog(); // Initialize PostHog
  initSupabase(() => {
    app();
    initNavbar(); // Initialize navbar with logout functionality
  });
});
