export class UserStateManager {
  private static instance: UserStateManager;
  private bodyElement: HTMLBodyElement;

  private constructor() {
    this.bodyElement = document.body as HTMLBodyElement;
    this.initStyles();
  }

  private initStyles() {
    const style = document.createElement('style');
    style.textContent = `
      [ss-user-state='is-logged-in'] {
        display: none;
      }

      [ss-user-state='!is-logged-in'] {
        display: none;
      }

      [is-logged-in] [ss-user-state='is-logged-in'] {
        display: block;
      }

      body:not([is-logged-in]) [ss-user-state='!is-logged-in'] {
        display: block;
      }
    `;
    document.head.appendChild(style);
  }

  static getInstance(): UserStateManager {
    if (!UserStateManager.instance) {
      UserStateManager.instance = new UserStateManager();
    }
    return UserStateManager.instance;
  }

  updateLoginState(isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.bodyElement.setAttribute('is-logged-in', '');
    } else {
      this.bodyElement.removeAttribute('is-logged-in');
    }
  }
}

export const userStateManager = UserStateManager.getInstance();