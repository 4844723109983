import {
  SupabaseClientOptions,
  createClient,
} from "@supabase/supabase-js";
import { setUser } from "../auth";
import { userStateManager } from '../../utils/auth/userState';
import { identifyUser } from '../../utils/posthogUser';

const options: SupabaseClientOptions<string> = {
  db: {
    schema: "public",
  },
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
  },
};

const supabaseUrl = process.env.SUPABASE_URL;
const supabaseKey = process.env.SUPABASE_KEY;

const supabase = createClient(
  supabaseUrl,
  supabaseKey,
  options
);

const handleUserSession = (session: any) => {
  if (session?.user?.user_metadata) {
    const user = session.user;
    setUser(
      user.user_metadata.fullName,
      user.email
    );
    identifyUser(user.id, {
      email: user.email,
      name: user.user_metadata.fullName,
    });
    userStateManager.updateLoginState(true);
  } else {
    userStateManager.updateLoginState(false);
  }
};

export const initSupabase = (cb: () => void) => {
  supabase.auth
    .getSession()
    .then((data) => {
      if (!data.error && data.data && data.data.session) {
        handleUserSession(data.data.session);
      } else {
        userStateManager.updateLoginState(false);
      }
    })
    .catch((err) => {
      console.log({ err });
      userStateManager.updateLoginState(false);
    })
    .finally(cb);
};

supabase.auth.onAuthStateChange((event, session) => {
  console.log('Auth state changed:', event, session);

  switch (event) {
    case 'SIGNED_IN':
    case 'USER_UPDATED':
    case 'INITIAL_SESSION':
      handleUserSession(session);
      break;

    case 'SIGNED_OUT':
      userStateManager.updateLoginState(false);
      break;
  }
});

export default supabase;
